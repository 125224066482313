<template>
	<div class="uploadbox">
		<input ref="excel-upload-input" class="excel-upload-input" type="file" accept=".xlsx, .xls"
			@change="handleClick">
		<div class="drop" @drop="handleDrop" @dragover="handleDragover" @dragenter="handleDragover">
			拖动文件至此处或
			<el-button :loading="loading" style="margin-left:16px;" size="mini" type="primary" @click="handleUpload">
				点击上传
			</el-button>
		</div>
	</div>
</template>

<script>
	import XLSX from 'xlsx'

	export default {
		props: {
			beforeUpload: Function, // eslint-disable-line
			onSuccess: Function ,// eslint-disable-line
			cellDates:{
				type:Boolean,
				default:false
			}
		},
		data() {
			return {
				loading: false,
				excelData: {
					header: null,
					results: null,
					fileName: null
				}
			}
		},
		methods: {
			generateData({
				header,
				results,
				fileName
			}) {
				this.excelData.header = header
				this.excelData.results = results
				this.excelData.fileName = fileName
				this.onSuccess && this.onSuccess(this.excelData)
			},
			handleDrop(e) {
				e.stopPropagation()
				e.preventDefault()
				if (this.loading) return
				const files = e.dataTransfer.files
				if (files.length !== 1) {
					this.$util.eltips('请上传单个文件', 'error')
					return
				}
				const rawFile = files[0] // only use files[0]

				if (!this.isExcel(rawFile)) {
					this.$util.eltips('仅支持 .xlsx, .xls, .csv 文件', 'error')
					return false
				}
				this.upload(rawFile)
				e.stopPropagation()
				e.preventDefault()
			},
			handleDragover(e) {
				e.stopPropagation()
				e.preventDefault()
				e.dataTransfer.dropEffect = 'copy'
			},
			handleUpload() {
				this.$refs['excel-upload-input'].click()
			},
			handleClick(e) {
				const files = e.target.files
				const rawFile = files[0] // only use files[0]
				if (!rawFile) return
				this.upload(rawFile)
			},
			upload(rawFile) {
				this.$refs['excel-upload-input'].value = null // fix can't select the same excel

				if (!this.beforeUpload) {
					this.readerData(rawFile)
					return
				}
				const before = this.beforeUpload(rawFile)
				if (before) {
					this.readerData(rawFile)
				}
			},
			readerData(rawFile) {
				this.loading = true
				return new Promise((resolve, reject) => {
					const reader = new FileReader()
					reader.onload = e => {
						const data = e.target.result
						const workbook = XLSX.read(data, {
							type: 'array',
							cellDates: this.cellDates
						})
						const firstSheetName = workbook.SheetNames[0]
						const worksheet = workbook.Sheets[firstSheetName]
						const header = this.getHeaderRow(worksheet)
						const results = XLSX.utils.sheet_to_json(worksheet, {defval: ''})
						const fileName = rawFile.name
						this.generateData({
							header,
							results,
							fileName
						})
						this.loading = false
						resolve()
					}
					reader.readAsArrayBuffer(rawFile)
				})
			},
			getHeaderRow(sheet) {
				const headers = []
				const range = XLSX.utils.decode_range(sheet['!ref'])
				let C
				const R = range.s.r
				/* start in the first row */
				for (C = range.s.c; C <= range.e.c; ++C) {
					/* walk every column in the range */
					const cell = sheet[XLSX.utils.encode_cell({
						c: C,
						r: R
					})]
					/* find the cell in the first row */
					let hdr = 'UNKNOWN ' + C // <-- replace with your desired default
					if (cell && cell.t) hdr = XLSX.utils.format_cell(cell)
					headers.push(hdr)
				}
				return headers
			},
			isExcel(file) {
				return /\.(xlsx|xls|csv)$/.test(file.name)
			}
		}
	}
</script>

<style scoped>
	.excel-upload-input {
		display: none;
		z-index: -9999;
	}

	.uploadbox {
		width: 100%;
		height: 160px;
	}

	.drop {
		border: 1px solid #DCDFE6;
		width: 100%;
		height: 160px;
		line-height: 160px;
		font-size: 18px;
		border-radius: 4px;
		color: #999;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.drop:hover {
		border-color: #1989fa;
		border-style: dashed;
	}
</style>